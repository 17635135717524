<template>
  <div class="ddj-offer">
    <div class="main-Title bgff"><h2>Offer</h2></div>
    <div class="mainBox">
      <el-card shadow="hover">
        <el-form :model="filter" label-width="80px" size="small">
          <el-row>
            <el-col :span="4">
              <el-form-item label="offer ID:"
                ><el-input
                  v-model="filter.offerId"
                  placeholder="Please enter offer ID"
                  class="mr10"
                ></el-input
              ></el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="Country:">
                <el-select v-model="filter.country" placeholder="Please select" class="mr10">
                  <el-option label="全部" value="" />
                  <el-option
                    v-for="item in options.country"
                    :key="item.id"
                    :value="item.countryCode"
                    :label="item.countryCode"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="OS:" label-width="50px">
                <el-select v-model="filter.os" placeholder="Please select OS" class="mr10">
                  <el-option label="全部" value="" />
                  <el-option
                    v-for="item in options.os"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="Status:" label-width="60px">
                <el-select v-model="filter.status" placeholder="Please select Status" class="mr10">
                  <el-option label="全部" value="" />
                  <el-option
                    v-for="item in options.status"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="Advertiser:">
                <el-select
                  v-model="filter.advertiserName"
                  placeholder="Please select Advertiser"
                  class="mr10"
                >
                  <el-option label="全部" value="" />
                  <el-option
                    v-for="item in options.advertiser"
                    :key="item.id"
                    :value="item.advertiserName"
                    :label="item.advertiserName"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="Offer Type:" label-width="90px">
                <el-select
                  v-model="filter.sourceType"
                  placeholder="Please select Offer Type"
                  class="mr10"
                >
                  <el-option label="全部" value="" />
                  <el-option
                    v-for="item in options.offerType"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="2"
              ><el-button type="primary" size="mini" @click="filterFun('filter')">Search</el-button>
              <el-button type="success" size="mini" @click="add()">Add</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          style="width:100%;"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column label="offer ID" width="100px" prop="offerId"></el-table-column>
          <el-table-column label="offer Name" prop="title"></el-table-column>
          <el-table-column
            label="offer URL"
            show-overflow-tooltip
            prop="trackingLink"
          ></el-table-column>
          <el-table-column label="OS" prop="os"></el-table-column>
          <el-table-column label="Country" prop="country"></el-table-column>
          <el-table-column label="Price" prop="price"></el-table-column>
          <el-table-column label="CAP" prop="clickCap"></el-table-column>
          <el-table-column label="Status" prop="status">
            <template slot-scope="scope">
              <el-tag
                :type="
                  scope.row.status == 'active' || scope.row.status == 'supplement'
                    ? 'success'
                    : 'danger'
                "
                effect="dark"
                size="mini"
                >{{ scope.row.status }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="上游名称" prop="advertiserName"></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                size="mini"
                @click="edit(scope.row.id)"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="del(scope.row, scope.row.offerId)"
              ></el-button>
              <el-tooltip class="item" effect="dark" content="添加任务" placement="top">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  circle
                  size="mini"
                  @click="
                    addTask(
                      scope.row.offerId,
                      scope.row.country,
                      scope.row.id,
                      scope.row.os,
                      scope.row.advertiserName
                    )
                  "
                ></el-button>
              </el-tooltip>
              <!-- <el-tooltip class="item" effect="dark" content="子渠道配置" placement="top">
                <el-button
                  type="primary"
                  icon="el-icon-s-tools"
                  circle
                  size="mini"
                  @click="more(scope.row.offerId, scope.row.advertiserName)"
                ></el-button>
              </el-tooltip> -->
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <div v-if="isShowPage" class="pagination-container pt-10" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-card>
      <el-dialog title="子渠道配置" :visible.sync="diallog.more">
        <el-form ref="SubAffData" :model="SubAffData" :rules="rules" label-width="150px">
          <el-form-item label="offer ID" prop="offerId"
            ><el-input v-model="SubAffData.offerId" placeholder="请输入offer ID" :disabled="true"
          /></el-form-item>
          <el-form-item label="Sub_aff" prop="subAff">
            <el-button type="primary" size="mini" @click="randomFun()">随机生成</el-button>
            <div class="random">{{ random }}</div>
            <el-input
              type="textarea"
              placeholder="请输入subAff"
              :autosize="{ minRows: 5, maxRows: 10 }"
              v-model="SubAffData.subAff"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="diallog.more = false">取 消</el-button>
          <el-button type="primary" @click="submitSubAff()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import ddjOfferCon from '../../controllers/DDJ/offer/list';
  export default {
    name: 'ddjOffer',
    ...ddjOfferCon,
  };
</script>

<style></style>
